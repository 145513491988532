import React from "react"

const GoogleMap = ({ extraClassName }) => {
  return (
    <div className={`google-map__${extraClassName}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8058.191591401509!2d85.2911204542404!3d27.71947562074465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18ece8135209%3A0xa82eb67cf4731fa8!2sSwayambhu%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1692201335867!5m2!1sen!2snp"
        className={`map__${extraClassName}`}
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default GoogleMap
